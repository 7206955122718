import type DateField from '@naturehouse/design-system/components/atoms/date-field/DateField';
import { Observer } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import { formatToDateString } from '@naturehouse/nh-essentials/lib/dates/date';
import DatePickerCalendar, {
    DatePickerCalendarEvents,
    DatePickerCalendarSelectState
} from '../../../calendar/webComponents/DatePickerCalendar';
import ArrivalDatesCollection, {
    ArrivalDatesCollectionEndpointParams
} from './arrival-dates/ArrivalDatesCollection';
import ArrivalDatesStorageManager from './arrival-dates/ArrivalDatesStorageManager';

export default class ArrivalDatesToCalendarConnector implements Observer {
    readonly #arrivalDatesStorageManager: ArrivalDatesStorageManager;

    readonly #houseId: string;

    #startDate: Date;

    #endDate: Date;

    readonly #datepickerCalendar: DatePickerCalendar = document.querySelector(
        'datepicker-calendar'
    ) as DatePickerCalendar;

    readonly #arrivalDateInput: HTMLInputElement | null = document.querySelector(
        '[data-role="arrival-date-input"]'
    );

    readonly #arrivalDateField: DateField | null = document.querySelector(
        'nh-date-field[data-name="arrivalDate"]'
    );

    #firstLoad = true;

    public constructor({ startDate, endDate }: { startDate: Date | null; endDate: Date | null }) {
        const element: HTMLMetaElement = document.getElementById('house-id') as HTMLMetaElement;

        this.#houseId = element.content;

        this.#startDate = startDate ?? new Date();
        const newEndDate = endDate ?? this.#startDate;
        this.#endDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth() + 4, 0);

        this.#arrivalDatesStorageManager = ArrivalDatesStorageManager.getInstance();
        this.#arrivalDatesStorageManager.attach(this);
    }

    public async initialize(): Promise<void> {
        await this.#arrivalDatesStorageManager.fetchInitialData(this.#getParams());
        this.#setEventListeners();
    }

    public update(event: CustomEvent): void {
        if (this.#firstLoad) {
            this.#datepickerCalendar.firstArrivalDate = event.detail.data[0]
                ? new Date(event.detail.data[0])
                : new Date();
            this.#datepickerCalendar.allowedDates = event.detail.data;
            this.#firstLoad = false;
            this.#startDate = new Date();
            return;
        }

        this.#datepickerCalendar.firstArrivalDate = null;
        this.#datepickerCalendar.allowedDates = event.detail.data;
    }

    #setEventListeners(): void {
        this.#datepickerCalendar.addEventListener(
            DatePickerCalendarEvents.SHOW_MORE_MONTHS,
            this.#handleShowMoreMonths
        );

        if (this.#arrivalDateField) {
            this.#arrivalDateField.addEventListener('click', this.#retrieveArrivalDates);
            this.#arrivalDateField.addEventListener('clear', this.#resetArrivalDates);
        }

        if (this.#arrivalDateInput) {
            this.#arrivalDateInput.addEventListener('click', this.#retrieveArrivalDates);
            this.#arrivalDateInput.addEventListener('clear', this.#resetArrivalDates);
        }
    }

    #extendDateRange(extendDateRangeWithMonths: number): void {
        this.#startDate = new Date(this.#endDate);
        this.#endDate = new Date(
            this.#endDate.getFullYear(),
            this.#endDate.getMonth() + extendDateRangeWithMonths + 1,
            0
        );
    }

    readonly #handleShowMoreMonths = async (event: Event): Promise<void> => {
        const customEvent = event as CustomEvent<{
            extendDateRangeWithMonths: number;
            state: DatePickerCalendarSelectState | null;
        }>;

        if (customEvent.detail.state === DatePickerCalendarSelectState.END) {
            return;
        }

        try {
            this.#extendDateRange(customEvent.detail.extendDateRangeWithMonths);
            await ArrivalDatesCollection.getInstance().retrieve(this.#getParams());
        } catch (e) {
            await this.#arrivalDatesStorageManager.getStoredData();
        }
    };

    readonly #resetArrivalDates = async (): Promise<void> => {
        this.#startDate = new Date();
        await this.#retrieveArrivalDates();
    };

    readonly #retrieveArrivalDates = async (): Promise<void> => {
        try {
            await ArrivalDatesCollection.getInstance().retrieve(this.#getParams());
        } catch (e) {
            await this.#arrivalDatesStorageManager.getStoredData();
        }
    };

    readonly #getParams = (): ArrivalDatesCollectionEndpointParams => ({
        houseId: this.#houseId,
        searchParams: new URLSearchParams({
            start_date: formatToDateString(this.#startDate),
            end_date: formatToDateString(this.#endDate)
        })
    });
}
