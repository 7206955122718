var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/image/Image.macro.njk"] = require( "@naturehouse/design-system/components/atoms/image/Image.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/organisms/image-viewer/partials/_slider.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/atoms/image/Image.macro.njk", false, "design-system/v3/components/organisms/image-viewer/partials/_slider.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("imageTemplate", t_1);
output += "\n\n<nh-slides-carousel\n    class=\"nh-image-viewer__slider\"\n    data-role=\"images\"";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "loadNextImage"),true) == true) {
output += " load-next-image";
;
}
output += "\n>\n    ";
frame = frame.push();
var t_6 = runtime.contextOrFrameLookup(context, frame, "slides");
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_5 = t_6.length;
for(var t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4];
frame.set("slide", t_7);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += "\n        <div slot=\"slide\" data-role=\"image-slide\">\n            ";
output += runtime.suppressValue((lineno = 9, colno = 35, runtime.callWrap(runtime.memberLookup((t_1),"render"), "imageTemplate[\"render\"]", context, [{"src": runtime.memberLookup((t_7),"src"),"sizes": env.getFilter("default").call(context, runtime.memberLookup((t_7),"sizes"),""),"alt": runtime.memberLookup((t_7),"alt"),"loading": runtime.memberLookup((t_7),"loading"),"data": {"data-srcset": env.getFilter("default").call(context, runtime.memberLookup((t_7),"srcset"))}}])), env.opts.autoescape);
output += "\n        </div>\n    ";
;
}
}
frame = frame.pop();
output += "\n</nh-slides-carousel>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/organisms/image-viewer/partials/_slider.html.njk"] , dependencies)