/* eslint-disable import/no-duplicates */
import '@naturehouse/design-system/components/protons/icon/Icon';
import '@naturehouse/design-system/components/atoms/date-field/DateField';
import '@naturehouse/design-system/components/atoms/impact-house-tag/ImpactHouseTag';
import '@naturehouse/design-system/components/atoms/travel-company-input/TravelCompanyInput';
import '@naturehouse/design-system/components/molecules/calendar-week-days/CalendarWeekDays';
import Curtain from '@naturehouse/design-system/components/molecules/curtain/Curtain';
import Dialog, {
    DialogEvents
} from '@naturehouse/design-system/components/molecules/dialog/Dialog';
import '@naturehouse/design-system/components/molecules/show-more/ShowMore';
import '@naturehouse/design-system/components/molecules/slides-carousel/SlidesCarousel';
import '@naturehouse/design-system/components/organisms/image-carousel/ImageCarousel';
import { ImageCarouselEvent } from '@naturehouse/design-system/components/organisms/image-carousel/ImageCarousel';
import { ImageViewerEvents } from '@naturehouse/design-system/components/organisms/image-viewer/ImageViewer';
import '@naturehouse/design-system/components/templates/image-carousel-viewer/ImageCarouselViewer';
import ImageCarouselViewer, {
    ImageCarouselViewerEvent
} from '@naturehouse/design-system/components/templates/image-carousel-viewer/ImageCarouselViewer';
import { onIntersectionObserver } from '@naturehouse/nh-essentials/lib/events/eventHandling';
import { handleScrollToTargetElements } from '@naturehouse/nh-essentials/lib/dom/handleScrollToTargetElements';
import { matchMediaAddEventListener } from '@naturehouse/nh-essentials/lib/polyfills/matchMedia';
import { isSameDomain } from '@naturehouse/nh-essentials/lib/url/isSameDomain';
import GoogleAnalyticsEvent from '../../common/GoogleAnalyticsEvents';
import '../../ui/web-components/travelParty/TravelParty';
import RecentlyViewedHousesManager from '../app/RecentlyViewedHousesManager';
import '../calendar/webComponents/CalendarInput';
import '../calendar/webComponents/DatePickerCalendar';
import DatePickerCalendar from '../calendar/webComponents/DatePickerCalendar';
import Module from '../module';
import ModalComponent from '../shared/components/ModalComponent';
import HouseDetailTrackingEvent from './HouseDetailTracking';
import './components/DetailForm';
import OpenNewChat from './components/OpenNewChat';
import './components/Translations/TranslatableContent';
import TranslatableContentManager from './components/Translations/TranslatableContentManager';
import './components/Translations/TranslatableContentSwitch';
import './components/Translations/TranslationFetcher';
import ArrivalDatesToCalendarConnector from './components/calendar/ArrivalDatesToCalendarConnector';
import MapsComponent from './components/mapsComponent';
import ReviewListManager from './components/reviews/ReviewListManager';
import ReviewPaginationHandler from './components/reviews/ReviewPaginationHandler';
import DetailBackButtonManager from './navigateBackButton/DetailBackButtonManager';
import SustainabilityDialogManager from './sustainability/SustainabilityDialogManager';
import '../../../css/views/listing.pcss';

export default class ListingModule extends Module {
    readonly #imageCarouselViewer = document.querySelector<ImageCarouselViewer>(
        'nh-image-carousel-viewer'
    );

    readonly #options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
    };

    constructor() {
        super();

        this.components.push(MapsComponent);
        this.components.push(OpenNewChat);
        this.components.push(ModalComponent);

        TranslatableContentManager.getInstance();
        RecentlyViewedHousesManager.getInstance();

        ReviewPaginationHandler.getInstance();
        ReviewListManager.getInstance();

        handleScrollToTargetElements();

        const openFacilitiesModalButton: HTMLElement | null = document.querySelector(
            '[data-role="open-facilities-modal"]'
        );

        const facilitiesModal: Dialog | null = document.querySelector('#detail-facilities-dialog');

        this.#imageCarouselViewer = document.querySelector<ImageCarouselViewer>(
            'nh-image-carousel-viewer'
        );

        if (openFacilitiesModalButton && facilitiesModal) {
            openFacilitiesModalButton.addEventListener('click', (): void => {
                facilitiesModal.showModal();
                GoogleAnalyticsEvent.trackEvent(
                    HouseDetailTrackingEvent.OPEN_FACILITIES_BUTTON_CLICK
                );
            });

            const mq: MediaQueryList = window.matchMedia('(min-width: 1024px)');

            matchMediaAddEventListener(mq, (e) => {
                if (e.matches) {
                    facilitiesModal.close();
                }
            });
        }

        const houseId = document.getElementById('house-id')?.getAttribute('content');
        if (houseId) {
            const sustainabilityDialogManager = new SustainabilityDialogManager(houseId);
            sustainabilityDialogManager.initialize();
        }

        this.#addImageCarouselTracking();
        this.#addImageViewerTracking();
        this.#addJumpToReviewButtonTracking();
        this.#addDescriptionReadMoreButtonTracking();
        this.#addContactWithLandlordButtonTracking();
        this.#addShowLocationButtonTracking();

        if (!('IntersectionObserver' in window)) {
            return;
        }

        this.#addDescriptionIntersectionTracking();
        this.#addFacilitiesIntersectionTracking();
        this.#addMapIntersectionTracking();
        this.#addGoodToKnowIntersectionTracking();
        this.#addReviewsIntersectionTracking();

        if (isSameDomain(document.referrer)) {
            DetailBackButtonManager.getInstance();
        }
    }

    public async init(): Promise<void> {
        super.init();

        await this.#initializeArrivalDatesToCalendarConnector();
    }

    readonly #initializeArrivalDatesToCalendarConnector = async (): Promise<void> => {
        // @see DetailForm.ts:85, the datepicker-calendar element is taken from a template and later added to the modal
        // the custom-elements API resolves the component too late before this code is executed,
        // therefore we have to wait until the datepicker-calendar is defined
        await customElements.whenDefined('datepicker-calendar');

        const datePickerCalendar = document.querySelector(
            'datepicker-calendar'
        ) as DatePickerCalendar | null;

        if (!datePickerCalendar) {
            return;
        }

        const selectedDateRange = datePickerCalendar.selectedDateRange;

        const arrivalDatesToCalendarConnector = new ArrivalDatesToCalendarConnector({
            startDate: datePickerCalendar.firstArrivalDate,
            endDate: selectedDateRange.end ?? selectedDateRange.start
        });

        await arrivalDatesToCalendarConnector.initialize();
    };

    readonly #addImageCarouselTracking = (): void => {
        if (!this.#imageCarouselViewer || !this.#imageCarouselViewer.carousel) {
            return;
        }

        this.#imageCarouselViewer.carousel.addEventListener(
            ImageCarouselEvent.NEXT_BUTTON_CLICK,
            () => GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.NEXT_IMAGE_CLICK)
        );

        this.#imageCarouselViewer.carousel.addEventListener(
            ImageCarouselEvent.PREV_BUTTON_CLICK,
            () => GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.PREV_IMAGE_CLICK)
        );

        this.#imageCarouselViewer.carousel.addEventListener(
            ImageCarouselEvent.NEXT_SWIPE_ACTION,
            () => GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.NEXT_IMAGE_SWIPE)
        );

        this.#imageCarouselViewer.carousel.addEventListener(
            ImageCarouselEvent.PREV_SWIPE_ACTION,
            () => GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.PREV_IMAGE_SWIPE)
        );

        this.#imageCarouselViewer.carousel.addEventListener(
            ImageCarouselEvent.THUMBNAIL_CLICK,
            (event: Event): void => {
                if (!(event instanceof CustomEvent)) {
                    return;
                }
                GoogleAnalyticsEvent.trackEvent<GoogleAnalytics4ThumbnailClickEvent>(
                    HouseDetailTrackingEvent.THUMBNAIL_CLICK,
                    {
                        value: event.detail.index
                    }
                );
            }
        );

        this.#imageCarouselViewer.carousel.addEventListener(
            ImageCarouselEvent.SLIDE_CHANGED,
            (event: Event): void => {
                if (!(event instanceof CustomEvent)) {
                    return;
                }

                GoogleAnalyticsEvent.trackEvent<GoogleAnalytics4ScrollIntoViewEvent>(
                    HouseDetailTrackingEvent.IMAGE_SCROLL_INTO_VIEW,
                    {
                        type: 'carousel',
                        value: event.detail.index
                    }
                );
            }
        );
    };

    readonly #addImageViewerTracking = (): void => {
        if (!this.#imageCarouselViewer || !this.#imageCarouselViewer.viewer) {
            return;
        }

        this.#imageCarouselViewer?.addEventListener(
            ImageCarouselViewerEvent.IMAGE_CLICK,
            (event: Event): void => {
                if (!(event instanceof CustomEvent)) {
                    return;
                }

                GoogleAnalyticsEvent.trackEvent<GoogleAnalytics4ImageClickEvent>(
                    HouseDetailTrackingEvent.IMAGE_CLICK,
                    {
                        value: event.detail.index
                    }
                );
            }
        );

        this.#imageCarouselViewer.viewer.addEventListener(DialogEvents.OPENED, () =>
            GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.OPEN_GALLERY)
        );

        this.#imageCarouselViewer.viewer.addEventListener(ImageViewerEvents.NEXT_BUTTON_CLICK, () =>
            GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.GALLERY_NEXT_IMAGE_CLICK)
        );

        this.#imageCarouselViewer.viewer.addEventListener(ImageViewerEvents.PREV_BUTTON_CLICK, () =>
            GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.GALLERY_PREV_IMAGE_CLICK)
        );

        this.#imageCarouselViewer.viewer.addEventListener(ImageViewerEvents.NEXT_SWIPE_ACTION, () =>
            GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.GALLERY_NEXT_IMAGE_SWIPE)
        );

        this.#imageCarouselViewer.viewer.addEventListener(ImageViewerEvents.PREV_SWIPE_ACTION, () =>
            GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.GALLERY_PREV_IMAGE_SWIPE)
        );

        this.#imageCarouselViewer.viewer.addEventListener(
            ImageViewerEvents.SLIDE_CHANGED,
            (event: Event): void => {
                if (!(event instanceof CustomEvent)) {
                    return;
                }

                GoogleAnalyticsEvent.trackEvent<GoogleAnalytics4ScrollIntoViewEvent>(
                    HouseDetailTrackingEvent.IMAGE_SCROLL_INTO_VIEW,
                    {
                        type: 'viewer',
                        value: event.detail.index
                    }
                );
            }
        );
    };

    readonly #addJumpToReviewButtonTracking = (): void => {
        const button = document.querySelector('[data-role="jump-to-reviews"]');
        const reviewsCurtain = document.querySelector<Curtain>(
            '[data-role="review-section"] details'
        );

        if (button === null || reviewsCurtain === null) {
            return;
        }

        button.addEventListener('click', (): void => {
            if (reviewsCurtain.open === false) {
                reviewsCurtain.toggle();
            }
            GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.REVIEW_BUTTON_CLICK);
        });
    };

    readonly #addDescriptionReadMoreButtonTracking = (): void => {
        const showMore = document.querySelector('#nature_description_show_more');
        const button = showMore?.querySelector('button[data-role="toggle"]');

        button?.addEventListener('click', (): void => {
            GoogleAnalyticsEvent.trackEvent<GoogleAnalytics4DescriptionReadMoreClickEvent>(
                HouseDetailTrackingEvent.DESCRIPTION_READ_MORE_CLICK,
                {
                    value: showMore?.hasAttribute('open') ? 'close' : 'open'
                }
            );
        });
    };

    readonly #addContactWithLandlordButtonTracking = (): void => {
        const button = document.querySelector('[data-role="contact-with-landlord"]');

        button?.addEventListener('click', (): void =>
            GoogleAnalyticsEvent.trackEvent(
                HouseDetailTrackingEvent.CONTACT_WITH_LANDLORD_BUTTON_CLICK
            )
        );
    };

    readonly #addShowLocationButtonTracking = (): void => {
        const button = document.querySelector('[data-initialize-map]');

        button?.addEventListener('click', (): void =>
            GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.SHOW_LOCATION_BUTTON_CLICK)
        );
    };

    readonly #addDescriptionIntersectionTracking = (): void => {
        const showMore = document.querySelector<HTMLElement>('#nature_description_show_more');

        if (!showMore) {
            return;
        }

        onIntersectionObserver(
            showMore,

            (): void =>
                GoogleAnalyticsEvent.trackEvent(
                    HouseDetailTrackingEvent.DESCRIPTION_INTERSECTION_EVENT
                ),
            this.#options
        );
    };

    readonly #addFacilitiesIntersectionTracking = (): void => {
        const facilities = document.querySelector<HTMLElement>(
            '[data-role="facilities-section"]  details [data-role="content"]'
        );

        if (!facilities) {
            return;
        }

        onIntersectionObserver(
            facilities,
            (): void =>
                GoogleAnalyticsEvent.trackEvent(
                    HouseDetailTrackingEvent.FACILITIES_INTERSECTION_EVENT
                ),
            this.#options
        );
    };

    readonly #addMapIntersectionTracking = (): void => {
        const map = document.querySelector<HTMLElement>('[data-role="detail-map"]');

        if (!map) {
            return;
        }

        onIntersectionObserver(
            map,
            (): void =>
                GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.MAP_INTERSECTION_EVENT),
            this.#options
        );
    };

    readonly #addGoodToKnowIntersectionTracking = (): void => {
        const goodToKnow = document.querySelector<HTMLElement>(
            '[data-role="good-to-know"]  details [data-role="content"]'
        );

        if (!goodToKnow) {
            return;
        }

        onIntersectionObserver(
            goodToKnow,
            (): void =>
                GoogleAnalyticsEvent.trackEvent(
                    HouseDetailTrackingEvent.GOOD_TO_KNOW_INTERSECTION_EVENT
                ),
            this.#options
        );
    };

    readonly #addReviewsIntersectionTracking = (): void => {
        const reviews = document.querySelector<HTMLElement>(
            '[data-role="review-section"]  details [data-role="content"]'
        );

        if (!reviews) {
            return;
        }

        onIntersectionObserver(
            reviews,
            (): void =>
                GoogleAnalyticsEvent.trackEvent(
                    HouseDetailTrackingEvent.REVIEWS_INTERSECTION_EVENT
                ),
            this.#options
        );
    };
}

(async function listingModuleInit(): Promise<void> {
    const listingModule: ListingModule = new ListingModule();
    await listingModule.init();
})();
